import produce from 'immer';
import { makeReducer } from 'redux-utils';
import { TRIGGER_PAGE_CHANGE, triggerPageChange, setObject } from 'services/app/actions';
import { replace } from 'services/app-router/actions';
import { INITIAL_STATE_VIDEO_PLAYER } from './models';
import { IVideoPlayerState } from './interfaces';
import * as actions from './actions';

export const getInitialState = () => ({
  ...INITIAL_STATE_VIDEO_PLAYER,
});

export const videoPlayerReducer = makeReducer(
  {
    ...actions,
    replace,
    triggerPageChange,
    setObject,
  },
  getInitialState,
  (state, action) => {
    switch (action.type) {
      case actions.SET_VIDEO_SIZE: {
        const { height, width } = action.payload;

        return {
          ...state,
          size: { height, width },
        };
      }

      case TRIGGER_PAGE_CHANGE: {
        return produce(state, (draft: IVideoPlayerState) => {
          draft.viewer.playerContentLoading = true;
        });
      }

      case actions.SHOW_VIDEO_MODAL: {
        const { modalId } = action.payload;
        return {
          ...state,
          videoModal: {
            ...state.videoModal,
            modalId,
          },
        };
      }

      case actions.ENABLE_DEVICE_LIMIT: {
        return {
          ...state,
          viewer: {
            ...state.viewer,
            deviceLimitEnabled: action.payload,
          },
        };
      }

      case actions.DEVICE_KICKED_AT: {
        return {
          ...state,
          deviceKickedAt: action.payload,
        };
      }

      default: {
        return state;
      }
    }
  },
);

export default videoPlayerReducer;
